<template>
  <div
    class="blog"
    v-html="postContent"
  />
</template>

<script>
export default {
  name: 'Blog',
  data: function() {
    return {
      postContent: ''
    };
  },
  created: function() {
    // console.log("inject md here?");
  }
};
</script>

<style lang="scss" scoped>
.blog {
  padding: 120px 0;
}
</style>
